<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card elevation="1">
    <!-- <table-toolbar /> -->
    <template v-if="showPlayer">
      <v-expand-transition>
        <record-player @closed="closedPlayer" v-if="showPlayer" :file="file" />
      </v-expand-transition>

      <v-divider />
    </template>
    <v-toolbar elevation="0">
      <v-row no-gutters class="mt-4">
        <v-col cols="12" sm="2" class="pt-2">
          <v-switch label="With Check" inset v-model="onlyWithCheck"></v-switch>
        </v-col>
        <v-col cols="12" sm="2" class="pr-1">
          <v-select
            :items="inboundArray"
            v-model="onlyInboud"
            item-value="value"
            item-text="label"
            outlined
            @change="getCalls"
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            :rules="[rules.phone]"
            prepend-inner-icon="mdi-numeric-positive-1"
            outlined
            v-model="filternumber"
            dense
            clearable
            label="Search by Number"
            :disabled="filterpatient != null || filteremployee != null"
          ></v-text-field>
        </v-col>
        <v-col>
          <filter-by-clients
            @selectC="filteringPatient($event)"
            class="ml-1"
            :disabled="
              filteremployee != null ||
              (filternumber != null && filternumber != null)
            "
          />
        </v-col>
        <template v-if="isSuper || isAdmin || isSupervisor">
          <v-col>
            <v-autocomplete
              v-model="filteremployee"
              class="mx-1"
              label="Search by Employees"
              :items="workersf"
              :loading="loadingEmployees"
              :disabled="
                filterpatient != null ||
                (filternumber != null && filternumber != null)
              "
              item-text="fullname"
              item-value="uuid"
              autocomplete="off"
              clearable
              hide-details
              outlined
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="data.item.ocupation"
                    class="text-capitalize"
                    v-html="data.item.ocupation.name"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="data.item.phone"
                    class="text-capitalize"
                    v-html="data.item.phone"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </template>

        <v-col class="">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <v-col class="mx-2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>
        <template v-if="validClean">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                elevation="0"
                small
                rounded
                depressed
                fab
                v-on="on"
                v-bind="attrs"
                @click="_clearFilters"
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Clear filters</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        text
        @click="getCalls"
      >
        <v-icon>mdi-sync</v-icon>
        {{ $t("labelUpdate") }}
      </v-btn>
    </v-toolbar>

    <v-divider />
    <v-data-table
      :headers="heads"
      :items="callList"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="totalcalls"
      item-key="index"
      :loading="loading"
    >
      {{/* From agent */}}
      <template v-slot:[`item.from`]="{ item }">
        <div class="phone-number d-flex">
          <v-icon color="green" class="mr-1">mdi-phone-outgoing</v-icon>

          <div class="d-flex">
            <div class="cursor-pointer" @click="makeCallV(item.from)">
              {{ item.from }}
            </div>
            <div class="mx-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyPhone(item.from)"
                    v-on="on"
                    small
                    color="info"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy Phone</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>
      {{/* To agent */}}
      <template v-slot:[`item.to`]="{ item }">
        <div class="phone-number d-flex">
          <template v-if="admin">
            <v-icon color="green" class="mr-1">mdi-phone-incoming</v-icon>

            <div class="d-flex">
              <div class="cursor-pointer" @click="makeCallV(item.to)">
                {{ item.to }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPhone(item.to)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>
            <v-icon color="green" class="mr-1">
              {{
                item.inbound == 1 ? "mdi-phone-incoming" : "mdi-phone-outgoing"
              }}</v-icon
            >

            <div class="d-flex">
              <div
                class="cursor-pointer"
                @click="makeCallV(item.inbound == 1 ? item.from : item.to)"
              >
                {{ item.inbound == 1 ? item.from : item.to }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="
                        copyPhone(item.inbound == 1 ? item.from : item.to)
                      "
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
        </div>
      </template>
      {{/* Call status */}}
      <template v-slot:[`item.status`]="{ item }">
        <span
          v-if="item.status"
          class="text-caption d-inline-flex align-center"
        >
          <v-icon class="mr-1" :color="statusColor(item.status)">
            {{ itemStatus(item) }}
          </v-icon>
          {{ statusName(item.status) }}
        </span>
      </template>
      {{/* Duration */}}
      <template v-slot:[`item.duration`]="{ item }">
        <div class="text-body-2">{{ prettyTime(item.duration) }}</div>
      </template>
      {{/* Origin */}}
      <template v-slot:[`item.origin`]="{ item }">
        <template v-if="admin">
          <div class="text-body-2">{{ getFromCall(item).origin }}</div>
        </template>
        <template v-else>
          <div class="text-body-2">
            {{
              getFromCall(item).origin == "Employee"
                ? "MySelf"
                : getFromCall(item).origin
            }}
          </div>
        </template>
      </template>
      {{/* Name */}}
      <template v-slot:[`item.name`]="{ item }">
        <template v-if="admin">
          <div class="text-body-2">
            {{
              getFromCall(item).name == getName
                ? "MySelf"
                : getFromCall(item).name
            }}
          </div>
        </template>
        <template v-else>
          <div class="text-body-2">{{ getOtherName(item).name }}</div>
        </template>
      </template>
      <template v-slot:[`item.toname`]="{ item }">
        <template v-if="admin">
          <div class="text-body-2">
            {{
              getToCall(item).name == getName ? "MySelf" : getToCall(item).name
            }}
          </div>
        </template>
        <template v-else>
          <div class="text-body-2">{{ getToCall(item).name }}</div>
        </template>
      </template>
      {{/* Date */}}
      <template v-slot:[`item.createAt`]="{ item }">
        <div class="text-body-2">{{ formatDate(item.createAt) }}</div>
      </template>

      {{/* Remove record */}}

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <template
            v-if="item.timeMarcInit && item.timeMarcEnd && item.duration != 0"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loadingaudio"
                  :loading="loadingaudio && currentAudio == item && part"
                  color="secondary"
                  icon
                  @click="playRecordNocomplete(item)"
                >
                  <v-icon small>mdi-play-circle</v-icon>
                </v-btn>
              </template>
              <span>Play Recorded Call Mark</span>
            </v-tooltip>
          </template>
          <template v-if="item.recordUrl && item.duration != 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loadingaudio"
                  :loading="loadingaudio && currentAudio == item && !part"
                  color="primary"
                  icon
                  @click="playRecord(item)"
                >
                  <v-icon small>mdi-play-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Play Recorded Call</span>
            </v-tooltip>
          </template>
          <template v-if="isSuper">
            <v-btn color="red" icon @click="setRecordToRemove(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </div>

        <template>
          <!--   <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" icon @click="makeCall(item)">
                  <v-icon>mdi-phone-forward</v-icon>
                </v-btn>
              </template>
              <span>Make Call</span>
            </v-tooltip>
          </template> -->
          <!-- <btn-make-call :fromcall="true" :numberPatient="numberShow(item)" /> -->

          <!--  <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="setContact(item)"
                v-bind="attrs"
                v-on="on"
                text
                color="info"
                ><v-icon>mdi-message-text-outline</v-icon></v-btn
              >
            </template>
            <span>Send SMS</span>
          </v-tooltip> -->
          <!--  <template v-if="!isContact(numberShow(item))">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="gotoAdd(numberShow(item))"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  color="primary"
                  ><v-icon>mdi-account-plus</v-icon></v-btn
                >
              </template>
              <span>Add Contact</span>
            </v-tooltip>
          </template> -->

          <!-- <template v-if="item.voicemail[0] != null">
            <v-btn icon color="warning" @click="playRecord(item.voicemail[0])">
              <v-icon>mdi-play-circle-outline</v-icon></v-btn
            >
          </template> -->
        </template>
      </template>

      <not-records slot="no-data" />
    </v-data-table>
    {{/*Dialog Add Contact */}}
    <v-dialog
      v-model="addcontact.dialogAddContact"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Add Contact </v-card-title>
        <v-card-text>
          <v-form v-model="formValid">
            <div class="d-flex px-3">
              <v-text-field
                outlined
                dense
                v-model="addcontact.item.alias"
                :rules="[rules.required]"
                name="alias"
                label="Name"
              ></v-text-field>
            </div>

            <div class="d-flex">
              <div class="col-sm-6">
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-numeric-positive-1"
                  v-model="addcontact.item.number"
                  :rules="[rules.required, rules.phone]"
                  dense
                  name="number"
                  label="Phone"
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  outlined
                  v-model="addcontact.item.origin"
                  :rules="[rules.required]"
                  dense
                  name="Origin"
                  label="Origin"
                ></v-text-field>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelAdd" text color="error">Cancel</v-btn>
          <v-btn
            :disabled="!formValid"
            :loading="loadingContact"
            @click="confirmAdd"
            text
            color="primary"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    {{/* Confirm remove */}}
    <v-dialog
      v-model="dialogConfirmRemove"
      max-width="280"
      content-class="rounded-xl"
      :persistent="loading"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-regular rounded-xl">
          {{ $t("dialogTitle") }}
        </v-card-title>
        <v-layout class="pa-4" column>
          <div class="text-body-1 text-truncate d-flex mb-2">
            <v-icon color="green" class="mr-1">mdi-phone-outgoing</v-icon>
            <span class="dosis">{{ currentRecord.from }}</span>
          </div>
          <div class="text-body-1 text-truncate d-flex">
            <v-icon color="green" class="mr-1">mdi-phone-incoming</v-icon>
            <span class="dosis">{{ cleanPhone(currentRecord.to) }}</span>
          </div>
        </v-layout>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :disabled="loading"
            @click="dialogConfirmRemove = false"
            text
          >
            {{ $t("labelCancel") }}
          </v-btn>
          <v-btn color="red" :loading="loading" @click="remove" text>
            {{ $t("labelRemove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/**
 * Show all calls
 */
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
// import TableToolbar from "./TableToolbar.vue";
import rules from "@/components/account/rules";
import { CallList } from "@/mixins";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import RecordedCall from "@/models/RecordedCall";
import BtnMakeCall from "./buttons/BtnMakeCall.vue";
import formatPhone from "@/utils/formatPhone";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import RecordPlayer from "./RecordPlayer.vue";
import { notifyInfo } from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import axios from "axios";
import { apiBaseUrl } from "@/enviorment";
import { funtionsPhone } from "@/utils/mixins";
import FilterByClients from "@/components/filters/FilterByClients.vue";
export default Vue.extend({
  name: "call-view",
  mixins: [CallList, funtionsPhone],
  components: {
    RecordPlayer,
    NotRecords,
    BtnMakeCall,
    MaDatePicker,
    FilterByClients,
    // TableToolbar,
  },
  data() {
    return {
      formValid: false,
      showPlayer: false,
      part: false,
      currentAudio: null,
      loadingaudio: false,
      onlyInboud: null,
      inboundArray: [
        { value: null, label: "All" },
        { value: false, label: "Outgoing" },
        { value: true, label: "Incoming" },
      ],
      file: "",
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      currentRecord: {
        uuid: "66efaa8b-7d8c-4a44-8d94-24aede53f2f7",
        from: "+111111111",
        to: "+122222222",
      },
      dialogConfirmRemove: false,
      footerProps: { "items-per-page-options": [15, 50, 100, 1000] },
      options: {},
      filterRange: "year",
      filternumber: null,
      filterpatient: null,
      filteremployee: null,
      onlyWithCheck: false,
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        dateRange: {
          date1: "",
          date2: "",
        },
        phoneNumber: null,
        patientUuid: null,
        employeeUuid: null,
      },
      dateFrom: "",
      dateTo: "",
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "toname",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },

        /* {
          text: this.$t("status"),
          value: "status",
          align: "center",
          sortable: false,
        }, */
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },

        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      addcontact: {
        dialogAddContact: false,
        item: { alias: "", number: "", origin: "Lead" },
      },
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getCalls();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },

    filternumber(val) {
      if (val != null) {
        this.filterpatient = null;
        localStorage.removeItem("uuidemployee");
        this.filteremployee = null;
        if (val.length == 10) {
          this.reqbody.phoneNumber = "+1" + val;
          this.getCalls();
        } else if (val.length == 0) {
          this.reqbody.phoneNumber = null;
          this.getCalls();
        }
      } else {
        this.reqbody.phoneNumber = null;
        this.getCalls();
      }
    },

    filteremployee(val) {
      if (val != null) {
        this.filterpatient = null;
        this.filternumber = null;
        this.reqbody.phoneNumber = null;
        this.reqbody.patientUuid = null;
        this.reqbody.employeeUuid = val;
        this.getCalls();
      } else {
        this.reqbody.phoneNumber = null;
        this.reqbody.employeeUuid = null;
        localStorage.removeItem("uuidemployee");
        this.getCalls();
      }
    },
    onlyWithCheck: {
      handler() {
        this.getCalls();
      },
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin) {
      if (this.employees.length == 0) {
        this.actGetEmployees();
      }
    }
  },

  destroyed() {
    this.mutRequest(null);
    this.mutFilter(false);
    localStorage.removeItem("uuidemployee");
  },
  computed: {
    ...mapState("crmCallCenterModule", [
      "callList",
      "loading",
      "totalcalls",
      "requestC",
      "filter",
      "dialogmakeCall",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),

    ...mapState(["userContacts", "loadingContact"]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getPhone",
      "isSupervisor",
      "getUser",
      "getName",
      "getpatientN",
    ]),

    workersf() {
      const w = this.employees.filter((e) => e.phone != null && e.phone != "");
      return w;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },

    admin() {
      if (this.isSuper || this.isAdmin || this.isSupervisor) {
        return true;
      }
      return false;
    },

    heads() {
      const temp = [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "to",
        },
        /*  {
          text: this.$t("status"),
          value: "status",
          align: "center",
          sortable: false,
        }, */
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },

        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
      return this.admin ? this.headers : temp;
    },
  },

  methods: {
    ...mapActions("crmCallCenterModule", [
      "actGetCalls",
      "actRemoveRecordedCall",
    ]),

    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
      "mutDialogmakeCall",
    ]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapMutations(["mutReqRange"]),

    filteringPatient(val) {
      if (val != null) {
        this.filternumber = null;
        this.filteremployee = null;
        localStorage.removeItem("uuidemployee");
        this.reqbody.patientUuid = val;
        this.getCalls();
      } else {
        this.reqbody.patientUuid = null;
        this.getCalls();
      }
    },

    makeCall(item) {
      const number = item.inbound == 1 ? item.from : item.to;

      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: number,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },

    closedPlayer() {
      this.file = "";
      this.showPlayer = false;
    },
    async playRecord(file) {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "audio/mpeg",
            };
      this.loadingaudio = true;
      this.part = false;
      this.currentAudio = file;
      const url = apiBaseUrl + "/zoom/getCallAudio/" + file.uuid + "/" + true;
      try {
        const response = await axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        });

        const buffer = new Blob([response.data]);

        let file_path = URL.createObjectURL(buffer);
        this.file = file_path;
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
        this.showPlayer = true;
      } catch (error) {
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
      }
    },
    async playRecordNocomplete(file) {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "audio/mpeg",
            };
      this.loadingaudio = true;
      this.currentAudio = file;
      this.part = true;
      const url = apiBaseUrl + "/zoom/getCallAudio/" + file.uuid + "/" + false;
      try {
        const response = await axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        });

        const buffer = new Blob([response.data]);

        let file_path = URL.createObjectURL(buffer);
        this.file = file_path;
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
        this.showPlayer = true;
      } catch (error) {
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
      }
    },
    openCallto(item) {
      localStorage.setItem("number", this.getTo(item));
      this.mutDialogmakeCall(true);
    },
    openCallfrom(item) {
      localStorage.setItem("number", this.getFrom(item));
      this.mutDialogmakeCall(true);
    },
    getNameTo(item) {
      if (item.to == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }
      if (item.to == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.to) {
        return item.patient_name;
      }
      if (item.l_phone == item.to) {
        return item.l_name;
      }
      return "-";
    },
    openCallact(item) {
      const n = this.numberShow(item);
      localStorage.setItem("number", n);
      this.mutDialogmakeCall(true);
    },

    gotoAdd(number) {
      let num = number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);

      this.addcontact = {
        dialogAddContact: true,
        item: {
          alias: "",
          number: num,
          origin: "Lead",
        },
      };
    },
    async confirmAdd() {
      let body = this.addcontact.item;
      let num = body.number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);
      body.number = "+1" + num;
      await this.actCreateContacts(body);
      this.cancelAdd();
    },

    cancelAdd() {
      this.addcontact = {
        dialogAddContact: false,
        item: { alias: "", number: "", origin: "Lead" },
      };
    },

    itemStatus(call) {
      if (call.status == "completed") {
        return "mdi-phone-check";
      } else if (call.status == "queued") {
        return "mdi-phone-settings";
      } else if (call.status == "ringing") {
        return "mdi-phone-in-talk";
      } else {
        if (
          call.from == this.getPhone ||
          call.from == `client:${this.getUser}`
        ) {
          return "mdi-phone-forward-outline";
        }
        return "mdi-phone-missed";
      }
    },

    _origin(item) {
      if (item.from == `client:${item.employee_username}`) {
        return "Employee";
      }

      if (item.from == item.employee_phone) {
        return "Employee";
      }
      if (item.patient_phone == item.from) {
        return "Patient";
      }
      if (item.l_phone == item.from) {
        return "Lead";
      }

      return "-";
    },
    _name(item) {
      if (item.from == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }

      if (item.from == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.from) {
        return item.patient_name;
      }
      if (item.l_phone == item.from) {
        return item.l_name;
      }

      return "-";
    },
    getFrom(call) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.from, call);
      }
    },
    getTo(call) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.to, call);
      }
    },
    getCalls() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      this.reqbody.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.filterRange == "custom") {
        this.reqbody.dateRange = {
          date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
          date2: moment(this.dateTo).endOf("day").utc().toISOString(),
        };
      }

      let body = Object.assign({}, this.reqbody);
      const uidEmpl = localStorage.getItem("uuidemployee");
      if (uidEmpl) {
        this.filteremployee = uidEmpl;
        body.employeeUuid = uidEmpl;
      }
      if (this.filteremployee != null) {
        delete body.phoneNumber;
      } else {
        delete body.employeeUuid;
      }

      if (this.onlyInboud != null) {
        body = { ...body, onlyInboud: this.onlyInboud };
      }
      body = { ...body, onlyWithCheck: this.onlyWithCheck };
      if (this.filterRange != "custom") {
        delete body.dateRange;
      }

      this.actGetCalls(body);
    },

    setRecordToRemove(record) {
      this.currentRecord = record;
      this.dialogConfirmRemove = true;
    },
    remove() {
      this.actRemoveRecordedCall(this.currentRecord.uuid).then(() => {
        this.dialogConfirmRemove = false;
        this.getCalls();
        const en = this.$i18n.locale == "en";
        const bodyEn = "Call deleted successfully";
        const bodyEs = "Llamada eliminada con éxito";

        notifyInfo(en ? bodyEn : bodyEs);
      });
    },

    _clearFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.filterRange = "year";
      this.clearFilters();
    },
    clearFilters() {
      this.filternumber = null;
      this.filterpatient = null;
      this.filteremployee = null;
      this.reqbody.phoneNumber = null;
      this.reqbody.employeeUuid = null;
      this.getCalls();
    },

    numberShow(call) {
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return call.to;
      } else {
        return call.from;
      }
    },

    setContact(item, type) {
      let phone = "";
      if (type == "to") {
        phone = this.getTo(item);
      } else if (type == "from") {
        phone = this.getFrom(item);
      } else {
        phone = this.numberShow(item);
      }

      const contact = { number: phone, name: phone };
      this.mutSetContact(contact);
      this.$router.push("/chat");
    },
  },
});
</script>

<i18n>
{
  "en": {
    "dialogTitle": "Delete call?",
    "missed": "Missed",
    "successful": "Successful",
    "origin":"Origin",
    "name":"Name"
  },
  "es": {
    "dialogTitle": "¿Eliminar llamada??",
    "missed": "Perdida",
    "successful": "Correcta",
    "origin":"Origen",
    "name":"Nombre"
  }
}
</i18n>
